<template >
  <div class="video-fiche">
    <div class="video-none video-box g-align-center">
      <video
        v-if="item.videoAddress"
        class="video-w video-js vjs-default-skin vjs-big-play-centered"
        controls
        :muted="false"
        :poster="item.videoAddress + '?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,ar_auto'"
      >
        <source :src="item.videoAddress" />
      </video>
      <div class="suspend g-align-center" v-if="isplay" @click="isplayClick(true)">
        <img v-if="is_andriod_ios()" :class="[typePrice == 1 ? 'pause-img':'pause-img2']" src="@/assets/images/pause-img.png" alt="">
      </div>
    </div>
    <VideoModel v-model="isplayShow" :typePrice="1" :item="item"></VideoModel>
    <!-- <div class="video-box video-model" v-if="isplayShow">
      <van-overlay :show="isplayShow" @click="isplayClick(false)">
        <video
          v-if="item.videoAddress"
          ref="video"
          class="video-w video-js vjs-default-skin vjs-big-play-centered"
          controls
          :muted="false"
        >
          <source :src="item.videoAddress" />
        </video>
        <div class="suspend g-align-center" v-if="isplay">
          <img :class="[typePrice == 1 ? 'pause-img':'pause-img2']" src="@/assets/images/pause-img.png" alt="" @click.stop="hendlePlay">
        </div>
      </van-overlay>
    </div> -->
  </div>
</template>
<script>
export default {
  name: 'video-fiche',
  components: {
    VideoModel: resolve => (require(['@/views/components/fiche/video-model.vue'], resolve))
  },
  props: {
    item: {
      type: [String, Object, Boolean],
      default: ''
    },
    typePrice: {
      type: [Number, Object, Boolean],
      default: 1
    }
  },
  data () {
    return {
      isplay: true,
      isplayShow: false
    }
  },
  watch: {
  },
  mounted() {
  },
  methods: {
    is_andriod_ios() {
      var u = navigator.userAgent
      return u.indexOf("Android") > -1 || u.indexOf("Linux") > -1 //android终端或者uc浏览器
    },
    isplayClick (val) {
      this.isplayShow = val
    }
  }
}
</script>
<style lang="less" scoped>

.video-fiche {
  height: 100%;
  .video-none {
    //所有控件
    video::-webkit-media-controls-enclosure{
      display: none;
      -webkit-appearance: none;
    }
    //播放按钮
    video::-webkit-media-controls-play-button {
      display: none;
      -webkit-appearance: none;
    }
    video::-webkit-media-controls {
      display:none !important;
      -webkit-appearance: none;
    }
    video::-webkit-media-controls-start-playback-button {
      display: none !important;
      -webkit-appearance: none;
    }
    video::-webkit-media-controls-panel {
      display: none!important;
      -webkit-appearance: none;
    }

    /* Old shadow dom for play button */

    video::-webkit-media-controls-play-button {
      display: none!important;
      -webkit-appearance: none;
    }

    /* New shadow dom for play button */

    /* This one works! */

    video::-webkit-media-controls-start-playback-button {
      display: none!important;
      -webkit-appearance: none;
    }


  }
  .video-box {
    position: relative;
    width: 100%;
    height: 100%;
    .video-w {
      width: 100%;
      height: 100%;
      z-index: 1;
    }
    .suspend {
      position: absolute;
      top: 0%;
      left: 0%;
      width: 100%;
      height: 100%;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      .pause-img {
        position: relative;
        z-index: 999;
        width: 70px;
        height: 70px;
        margin: 0 auto;
        border-radius: 50%;
        // background: rgba(255, 255, 255, 1);
      }
      .pause-img2 {
        width: 20px;
        height: 20px;
      }
    }
  }
}

</style>
